import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { PmsService } from '@app/services/pms.service';
import { SparePartsService } from '@app/services/spare-parts.service';
import { TicketsService } from '@app/services/tickets.service';
import { TurnoversService } from '@app/services/turnovers.service';
import { loadUnit } from '@dashboards/store/actions/dashboard.actions';
import { selectUnit } from '@dashboards/store/selectors/dashboards.selectors';
import { loadPropertyUsers } from '@main-application/administration/store/actions/administration.actions';
import {
  selectPropertyUsers,
  selectPropertyUsersLoading,
} from '@main-application/administration/store/selectors/administration.selectors';
import { Board, BoardColumn, ColumnOptions, RestBoardModel } from '@main-application/boards/interfaces/board';
import { BoardService } from '@main-application/boards/services/board.service';
import { TurnStepScheduleService } from '@main-application/boards/services/turn-step-schedule.service';
import {
  addInspectionTicket,
  addInspectionTicketFailed,
  addInspectionTicketSuccess,
  updateInspectionTicketFailed,
  updateInspectionTicketSuccess,
} from '@main-application/inspections/store/inspections.actions';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { selectUserData } from '@main-application/store/selectors/user.selectors';
import { TurnoverForm } from '@main-application/turnovers/config/enums/turnover-form';
import {
  addNewTurnoverTicket,
  addNewTurnoverTicketFailed,
  addNewTurnoverTicketSuccess,
  clearAddedTicketAttachment,
  clearTicketPreview,
  getTicket,
  getTicketAttachments,
  updateTicketPreview,
  updateTicketPreviewFailed,
  updateTicketPreviewSuccess,
} from '@main-application/turnovers/store/actions/turnovers.actions';
import {
  selectAddedTicketAttachment,
  selectTicket,
  selectTurnoverData,
} from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { IOption } from '@shared/components/button/button-menu/button-menu.component';
import { PriorityType } from '@shared/enums/priority-type';
import { TICKET_STATUS_TYPES, TicketStatusType } from '@shared/enums/ticket-status-type';
import { TicketType } from '@shared/enums/ticket-type';
import { getDateObjectFunction } from '@shared/functions/get-date-object.function';
import { FileUploadModel } from '@shared/interfaces';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestAddInspectionTicket, RestUpdateInspectionTicket } from '@shared/interfaces/ticket.interface';
import { TicketPreviewForm } from '@shared/interfaces/turnover-form.interface';
import {
  PaymentStatus,
  RestTicketModel,
  RestTurnoverDataModel,
  RestTypedTicketAttachment,
  TotalCostStatus,
} from '@shared/interfaces/turnover.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { skipEqual$ } from '@shared/utils/rxjs/skip-equal.rxjs.util';
import { CreateBoardColumnModalComponent } from '@ui-components/modals/create-board-column-modal/create-board-column-modal.component';

import { BoardColumnType } from './../../../main-application/boards/interfaces/board.enums';
import { DialogResult } from '../config/dialog-result.enum';

export interface TicketData {
  ticketId: number;
  isNew?: boolean;
  turnoverId?: number;
  assigneeId?: number;
  propertyName: string;
  propertyId: number;
  unitId: number;
  unitName: string;
  description?: string;
  title?: string;
  attachments?: FileUploadModel[];
  inspectionId?: number;
  inspectionContentId?: number;
  surveyPageId?: number;
}

interface TopicOption {
  value: number;
  label: string;
}

interface CategoryOption {
  value: number;
  label: string;
  topics: TopicOption[];
}

export enum ResponsiblePartyType {
  Unknown = 0,
  Landlord = 1,
  Resident = 2,
}

@UntilDestroy()
@Component({
  selector: 'app-add-ticket-modal',
  templateUrl: './add-ticket-modal.component.html',
  styleUrls: ['./add-ticket-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddTicketModalComponent extends ComponentAbstract implements OnInit, OnDestroy {
  customBoardOption = {
    value: -1,
    label: 'Not connected',
  };
  form: UntypedFormGroup = this.formBuilder.group({
    [TurnoverForm.ID]: [0],
    [TurnoverForm.NAME]: [null, [Validators.required, Validators.maxLength(255)]],
    [TurnoverForm.DETAILED_INFORMATION]: [null, [Validators.maxLength(1000)]],
    [TurnoverForm.ASSIGNEE_ID]: [null],
    [TurnoverForm.END]: [null, [Validators.required]],
    [TurnoverForm.TICKET_PRIORITY_TYPE]: [null, [Validators.required]],
    [TurnoverForm.TICKET_STATUS_TYPE]: [TicketStatusType.Todo],
    [TurnoverForm.ABLE_TO_RESOLVE]: [false],
    [TurnoverForm.BOARD_LAYOUTS_ID]: [this.customBoardOption.value],
    [TurnoverForm.BOARD_COLUMN_UID]: [null, [Validators.required]],
    pushToPMS: false,
    ticketCategoryId: null,
    ticketTopicId: null,
    permissionToEnter: [false],
    havePets: [false],
    accessNotes: [null, [Validators.maxLength(1000)]],
    problemDescription: [null, [Validators.maxLength(1000)]],
    technicalNotes: [null, [Validators.maxLength(1000)]],
    resolutionComment: [null, [Validators.maxLength(1000)]],
    timeSpentLoggedManuallyInput: null,
    totalCostFormatted: [''],
    totalCostStatus: [TotalCostStatus.Final],
    paymentStatusBool: [false],
    responsibleParty: [null],
    sparePartId: [null],
    sparePartTitle: [null],
    quantity: [null],
  });
  entryOptions: IRadioButtonOption<boolean | string>[] = [
    { value: true, label: 'Granted' },
    { value: false, label: 'Denied' },
    { value: 'null', label: 'Unknown' },
  ];
  petsOptions: IRadioButtonOption<boolean | string>[] = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
    { value: 'null', label: 'Unknown' },
  ];
  isPunchTicket = false;
  isInspectionTicket = false;
  isCostTicket = false;
  costStatus = [
    { value: TotalCostStatus.Estimate, label: 'Estimate' },
    { value: TotalCostStatus.Final, label: 'Final' },
  ];
  responsiblePartyType = [
    { value: ResponsiblePartyType.Unknown, label: 'Unknown' },
    { value: ResponsiblePartyType.Landlord, label: 'Landlord' },
    { value: ResponsiblePartyType.Resident, label: 'Resident' },
  ];
  updateInProgress$ = new BehaviorSubject(false);
  propertyUserListLoading$: Observable<boolean>;
  users: RestUserModel[] = [];
  attachmentItems: RestGenericTypedAttachment[] = [];
  cleanInputAttachment: EventEmitter<void> = new EventEmitter<void>();
  ticket: RestTicketModel = null;
  newAttachmentItem: AttachmentItem = null;
  formSubmitted = false;
  pmsType = 0;
  categories: CategoryOption[] = [];
  topics: TopicOption[] = [];
  isPushToPMSDisabled = false;
  isLoading = true;
  ticketStatusTypeValues = TicketStatusType;
  ticketStatusOptions$ = new BehaviorSubject<IOption<any>[]>(null);
  ticketStatusLabel!: string;
  statusType!: TicketStatusType;
  selectedStatus = {
    label: 'done',
    id: TicketStatusType.Resolved,
  };

  turnoverData!: RestTurnoverDataModel;
  board: Board;
  boardList: RestBoardModel[] = [];
  boardList$ = new BehaviorSubject<IRadioButtonOption<number>[] | null>(null);
  boardColumns$ = new BehaviorSubject<IRadioButtonOption<string>[] | null>(null);
  newColumnLoading$ = new BehaviorSubject<boolean>(false);
  formInitialized = false;
  isEarlyAssociation = false;
  spareItems$: Observable<IRadioButtonOption<number>[]> = this.sparePartsService.getItemList().pipe(
    map(items => {
      const uniqueItemsMap = new Map<string, typeof items[0]>();

      items
        .filter(i => i.title && i.title.trim() !== '-')
        .forEach(i => {
          const uniqueKey = `${i.title}-${i.cost}`;
          if (!uniqueItemsMap.has(uniqueKey)) {
            uniqueItemsMap.set(uniqueKey, i);
          }
        });

      return sortBy(
        Array.from(uniqueItemsMap.values()).map(i => ({
          value: i.id,
          label: `${i.title} (${i.cost}$)`,
        })),
        i => i.label
      );
    })
  );

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild('attachments', { read: ElementRef, static: false }) attachments: ElementRef<HTMLDivElement>;

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AddTicketModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: TicketData & { ticketType: TicketType; inspectionPmsType: number; fromServicePage?: boolean },
    private formBuilder: UntypedFormBuilder,
    private store: Store<{}>,
    private timezoneService: TimezoneService,
    private actions$: Actions,
    private ticketService: TicketsService,
    private boardService: BoardService,
    private turnoversService: TurnoversService,
    private dialog: MatDialog,
    private pmsService: PmsService,
    private turnStepScheduleService: TurnStepScheduleService,
    private sparePartsService: SparePartsService
  ) {
    super(cdr);
  }

  get name(): UntypedFormControl {
    return this.form.get(TurnoverForm.NAME) as UntypedFormControl;
  }

  get detailedInformation(): UntypedFormControl {
    return this.form.get(TurnoverForm.DETAILED_INFORMATION) as UntypedFormControl;
  }

  get assigneeId(): UntypedFormControl {
    return this.form.get(TurnoverForm.ASSIGNEE_ID) as UntypedFormControl;
  }

  get end(): UntypedFormControl {
    return this.form.get(TurnoverForm.END) as UntypedFormControl;
  }

  get ticketPriorityType(): UntypedFormControl {
    return this.form.get(TurnoverForm.TICKET_PRIORITY_TYPE) as UntypedFormControl;
  }

  get ticketStatusType(): UntypedFormControl {
    return this.form.get(TurnoverForm.TICKET_STATUS_TYPE) as UntypedFormControl;
  }

  get ableToResolve(): UntypedFormControl {
    return this.form?.get(TurnoverForm.ABLE_TO_RESOLVE) as UntypedFormControl;
  }

  get ticketCategoryId(): UntypedFormControl {
    return this.form.get('ticketCategoryId') as UntypedFormControl;
  }

  get ticketTopicId(): UntypedFormControl {
    return this.form.get('ticketTopicId') as UntypedFormControl;
  }

  get permissionToEnter(): UntypedFormControl {
    return this.form.get('permissionToEnter') as UntypedFormControl;
  }

  get havePets(): UntypedFormControl {
    return this.form.get('havePets') as UntypedFormControl;
  }

  get accessNotes(): UntypedFormControl {
    return this.form.get('accessNotes') as UntypedFormControl;
  }

  get problemDescription(): UntypedFormControl {
    return this.form.get('problemDescription') as UntypedFormControl;
  }

  get technicalNotes(): UntypedFormControl {
    return this.form.get('technicalNotes') as UntypedFormControl;
  }

  get resolutionComment(): UntypedFormControl {
    return this.form.get('resolutionComment') as UntypedFormControl;
  }

  get boardLayoutsId(): UntypedFormControl {
    return this.form.get(TurnoverForm.BOARD_LAYOUTS_ID) as UntypedFormControl;
  }

  get boardColumnUId(): UntypedFormControl {
    return this.form.get(TurnoverForm.BOARD_COLUMN_UID) as UntypedFormControl;
  }

  get timeSpentLoggedManuallyInput(): UntypedFormControl {
    return this.form.get('timeSpentLoggedManuallyInput') as UntypedFormControl;
  }

  get totalCostFormatted(): UntypedFormControl {
    return this.form.get('totalCostFormatted') as UntypedFormControl;
  }

  get totalCostStatus(): UntypedFormControl {
    return this.form.get('totalCostStatus') as UntypedFormControl;
  }

  get paymentStatusBool(): UntypedFormControl {
    return this.form.get('paymentStatusBool') as UntypedFormControl;
  }

  get sparePartId(): UntypedFormControl {
    return this.form.get('sparePartId') as UntypedFormControl;
  }

  get quantity(): UntypedFormControl {
    return this.form.get('quantity') as UntypedFormControl;
  }

  get responsibleParty(): UntypedFormControl {
    return this.form.get('responsibleParty') as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearTicketPreview());
  }

  ngOnInit(): void {
    this.isPunchTicket = this.data.ticketType === TicketType.Punch;
    this.isCostTicket = this.data.ticketType === TicketType.Cost;
    this.isInspectionTicket = this.data.ticketType === TicketType.Inspection;

    if (this.data.ticketId) {
      this.store.dispatch(getTicket({ ticketId: this.data?.ticketId }));
    }

    this.store.dispatch(loadPropertyUsers({ propertyId: this.data?.propertyId }));
    this.propertyUserListLoading$ = this.store.select(selectPropertyUsersLoading);

    this.store
      .select(selectPropertyUsers)
      .pipe(
        untilDestroyed(this),
        filter(users => !!users),
        tap(users => {
          this.users = users;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    combineLatest([
      this.store.select(selectUserData),
      this.store.select(selectTicket),
      this.data?.turnoverId ? this.turnoversService.getTurnover(this.data.turnoverId) : of(null),
    ])
      .pipe(
        untilDestroyed(this),
        filter(([userData, ticket]) => !!userData && (this.data.isNew || !!ticket)),
        tap(([userData, ticket, turnoverData]) => {
          if (ticket?.ticketType) {
            this.isPunchTicket = ticket.ticketType === TicketType.Punch;
            this.isCostTicket = ticket.ticketType === TicketType.Cost;
            this.data.ticketType = ticket.ticketType;
            this.cdr.detectChanges();
          }

          this.userData = userData;
          this.ticket = ticket;
          this.turnoverData = turnoverData;
          this.onGetFilteredBoardList(this.ticket?.boardLayoutsId);
          if (this.data.ticketId) this.setStatusTypes();
        }),
        switchMap(([_, ticket]) => {
          if (ticket?.unitId || this.data?.unitId) {
            const unitId = ticket?.unitId || this.data?.unitId;
            this.store.dispatch(loadUnit({ unitId }));
            return combineLatest([this.store.select(selectUnit)]).pipe(
              skipEqual$(),
              filter(([unit]) => !!unit),
              tap(([unit]) => {
                this.pmsType = unit.pmsType;
                // in some cases works multiple times, need to be fixed later, this may cause error
                this.initForm();
                this.cdr.detectChanges();
              })
            );
          } else {
            this.initForm();
            this.cdr.detectChanges();
            return [];
          }
        })
      )
      .subscribe();

    if (this.data.ticketType === TicketType.Inspection) {
      this.pmsType = this.data.inspectionPmsType;
    }

    if (this.data.ticketType !== TicketType.Inspection) {
      combineLatest([this.store.select(selectTurnoverData)])
        .pipe(
          filter(([turnoverData]) => !!turnoverData),
          untilDestroyed(this),
          tap(([turnoverData]) => {
            this.pmsType = turnoverData.unit.pmsType;
            this.cdr.detectChanges();
          })
        )
        .subscribe();
    }

    this.store
      .select(selectAddedTicketAttachment)
      .pipe(
        untilDestroyed(this),
        filter((addedTicketAttachment: RestTypedTicketAttachment) => !!addedTicketAttachment),
        tap((addedTicketAttachment: RestTypedTicketAttachment) => {
          this.cleanInputAttachment.emit();
          this.store.dispatch(clearAddedTicketAttachment());
          if (this.ticket?.id) {
            this.store.dispatch(getTicketAttachments({ ticketId: this.ticket.id }));
          }
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          addNewTurnoverTicketSuccess,
          addInspectionTicketSuccess,
          updateInspectionTicketSuccess,
          updateTicketPreviewSuccess
        ),
        untilDestroyed(this),
        tap(data => {
          if (
            this.isEarlyAssociation &&
            this.data?.ticketId &&
            !this.ticket?.turnStepScheduleId &&
            this.form.get(TurnoverForm.BOARD_LAYOUTS_ID)?.value &&
            this.form.get(TurnoverForm.BOARD_COLUMN_UID)?.value &&
            this.turnoverData?.id
          ) {
            this.onAttachTicket(
              this.data.ticketId,
              this.form.get(TurnoverForm.BOARD_LAYOUTS_ID).value,
              this.form.get(TurnoverForm.BOARD_COLUMN_UID).value,
              this.turnoverData.id
            );
          }
          this.updateInProgress$.next(false);
          this.dialogRef.close();
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          addNewTurnoverTicketFailed,
          addInspectionTicketFailed,
          updateInspectionTicketFailed,
          updateTicketPreviewFailed
        ),
        untilDestroyed(this),
        tap(data => this.updateInProgress$.next(false))
      )
      .subscribe();

    if (this.data.ticketType !== TicketType.Inspection) {
      this.actions$.pipe(ofType(addNewTurnoverTicketSuccess), take(1)).subscribe(() => {
        this.dialogRef.close(DialogResult.Success);
      });
    }

    if (this.data.isNew && !this.isInspectionTicket) {
      this.boardList$
        .pipe(
          filter(boardList => boardList !== null && boardList.length > 0),
          take(2),
          tap(boardList => {
            if (boardList?.[1]?.value) this.form.get(TurnoverForm.BOARD_LAYOUTS_ID).setValue(boardList[1].value);
          })
        )
        .subscribe();
    }

    this.boardColumnUId.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((selectedColumnId: string) => {
          const selectedColumn = this.board?.columns?.find(column => column.id === selectedColumnId);

          if (selectedColumn) {
            this.isCostTicket = selectedColumn.type === BoardColumnType.CustomAmount;
          } else {
            this.isCostTicket = false;
          }
        })
      )
      .subscribe();
  }

  deleteTicketAttachment(attachment: RestGenericTypedAttachment) {
    this.attachmentItems.splice(this.attachmentItems.indexOf(attachment), 1);
    this.cdr.detectChanges();
  }

  isSyncWithPmsEnabled = (pmsType: number) => this.pmsService.isSyncWithPmsEnabled(pmsType).pipe(untilDestroyed(this));

  addTicketAttachment(attachmentItem: AttachmentItem) {
    if (this.attachments) {
      setTimeout(() => {
        this.attachments.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 250);
    }
    if (attachmentItem.upload) {
      const { id, originalFileName, size, cloudUri } = attachmentItem.upload;
      this.attachmentItems = [
        ...this.attachmentItems,
        {
          id: -1,
          fileUploadId: id,
          size,
          originalFileName,
          url: cloudUri,
        },
      ];
    }

    this.cdr.detectChanges();
  }

  private fetchCategories(): Observable<void> {
    return this.ticketService.getCashedCategories().pipe(
      untilDestroyed(this),
      tap(data => {
        this.categories = data.map((category: any) => ({
          value: category.id,
          label: category.name,
          topics: category.topics.map((topic: any) => ({
            value: topic.id,
            label: topic.name,
          })),
        }));
      }),
      map(() => void 0)
    );
  }

  private getCategoryByTopicId(topicId: number): number | null {
    for (const category of this.categories) {
      for (const topic of category.topics) {
        if (topic.value === topicId) {
          return category.value;
        }
      }
    }
    return null;
  }

  private clearNewTicketAttachment() {
    this.cleanInputAttachment.emit();
    this.newAttachmentItem = null;
    this.cdr.detectChanges();
  }

  cancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.formGroupDirective) {
      this.formGroupDirective.onSubmit(new Event('submit'));
    }
  }

  onSave(statusType: TicketStatusType): void {
    this.statusType = statusType;
    this.onSubmit();
  }

  save(): void {
    if (!this.ticketService.checkPermissionToEdit(this.userData, this.ticket, true)) {
      return;
    }

    this.formSubmitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.cdr.detectChanges();
      return;
    }

    this.updateInProgress$.next(true);
    const formValue: TicketPreviewForm = this.form.value;

    const turnoverTicket: RestTicketModel = {
      ...this.ticket,
      id: this.data.isNew ? null : this.ticket.id,
      unitId: this.data.isNew ? this.data.unitId : this.ticket.unitId,
      endDate: formValue[TurnoverForm.END],
      detailedInformation: formValue[TurnoverForm.DETAILED_INFORMATION],
      assigneeId: formValue[TurnoverForm.ASSIGNEE_ID],
      ticketPriorityType: formValue[TurnoverForm.TICKET_PRIORITY_TYPE],
      turnoverId: this.data.isNew ? this.data.turnoverId : this.ticket.turnoverId,
      pushToPMS: formValue.pushToPMS,
      ticketTopicId: formValue.ticketTopicId,
      accessNotes: formValue.accessNotes,
      problemDescription: formValue.problemDescription,
      technicalNotes: formValue.technicalNotes,
      resolutionComment: formValue.resolutionComment,
      permissionToEnter: typeof formValue.permissionToEnter !== 'string' ? formValue.permissionToEnter : null,
      havePets: typeof formValue.havePets !== 'string' ? formValue.havePets : null,
      timeSpentLoggedManually: formValue.timeSpentLoggedManuallyInput
        ? this.ticketService.convertToSeconds(formValue.timeSpentLoggedManuallyInput)
        : null,
      totalCost: this.ticketService.parseCurrency(formValue.totalCostFormatted),
      totalCostStatus: formValue.totalCostStatus,
      paymentStatus: formValue.paymentStatusBool === true ? PaymentStatus.Paid : PaymentStatus.Unpaid,
      attachFileIds: this.attachmentItems.filter(a => this.needToAddAttachment(a)).map(a => a.fileUploadId),
      removeFileIds: this.ticket?.allAttachments.filter(a => this.needToRemoveAttachment(a)).map(a => a.fileUploadId),
      sparePartId: formValue.sparePartId,
      quantity: Number(formValue.quantity),
      responsibleParty: formValue.responsibleParty,
    };

    if (this.isCostTicket) {
      turnoverTicket.ticketType = TicketType.Cost;
    }

    if (
      formValue[TurnoverForm.BOARD_LAYOUTS_ID] === this.customBoardOption.value ||
      !formValue[TurnoverForm.BOARD_LAYOUTS_ID]
    ) {
      turnoverTicket.name = formValue[TurnoverForm.NAME];
    } else {
      turnoverTicket.boardLayoutsId = this.form.get(TurnoverForm.BOARD_LAYOUTS_ID).value;
      turnoverTicket.boardColumnUID = this.form.get(TurnoverForm.BOARD_COLUMN_UID).value;
    }

    if (this.data.ticketType !== TicketType.Other) {
      turnoverTicket.ticketType = this.data.ticketType;
    }

    if (this.statusType !== undefined && this.statusType !== turnoverTicket.ticketStatusType) {
      turnoverTicket.ticketStatusType = this.onGetTicketStatusType(this.statusType);

      if (this.statusType === this.ticketStatusTypeValues.Resolved)
        turnoverTicket.dateCompleted = this.timezoneService.getCurrentDate();

      this.ticketStatusChange.emit(this.statusType);
    }

    if (formValue.pushToPMS) {
      turnoverTicket.ticketCategoryId = formValue.ticketCategoryId;
      turnoverTicket.ticketTopicId = formValue.ticketTopicId;
    }

    if (this.data.isNew) {
      const ticket: RestAddInspectionTicket = {
        unitId: this.data.isNew ? this.data.unitId : this.ticket.unitId,
        dueDate: formValue[TurnoverForm.END],
        description: formValue[TurnoverForm.DETAILED_INFORMATION],
        assignee: formValue[TurnoverForm.ASSIGNEE_ID],
        ticketPriorityType: formValue[TurnoverForm.TICKET_PRIORITY_TYPE],
        turnoverId: this.data.isNew ? this.data.turnoverId : this.ticket.turnoverId,
        inspectionContentId: this.data.isNew ? this.data.inspectionContentId : this.ticket.inspectionContentId,
        inspectionId: this.data.isNew ? this.data.inspectionId : this.ticket.inspectionId,
        copyFileIds: this.attachmentItems.filter(a => this.needToCopyAttachment(a)).map(a => a.fileUploadId),
        attachFileIds: this.attachmentItems.filter(a => !this.needToCopyAttachment(a)).map(a => a.fileUploadId),
        surveyPageId: this.data.surveyPageId,
        pushToPMS: formValue.pushToPMS,
        ticketTopicId: formValue.pushToPMS ? formValue.ticketTopicId : null,
        accessNotes: formValue.accessNotes,
        problemDescription: formValue.problemDescription,
        technicalNotes: formValue.technicalNotes,
        resolutionComment: formValue.resolutionComment,
        permissionToEnter: typeof formValue.permissionToEnter !== 'string' ? formValue.permissionToEnter : null,
        havePets: typeof formValue.havePets !== 'string' ? formValue.havePets : null,
        timeSpentLoggedManually: formValue.timeSpentLoggedManuallyInput
          ? this.ticketService.convertToSeconds(formValue.timeSpentLoggedManuallyInput)
          : null,
        totalCost: this.ticketService.parseCurrency(formValue.totalCostFormatted),
        totalCostStatus: formValue.totalCostStatus,
        paymentStatus: formValue.paymentStatusBool === true ? PaymentStatus.Paid : PaymentStatus.Unpaid,
        sparePartId: formValue.sparePartId,
        quantity: Number(formValue.quantity),
        responsibleParty: formValue.responsibleParty,
      };
      if (
        formValue[TurnoverForm.BOARD_LAYOUTS_ID] === this.customBoardOption.value ||
        !formValue[TurnoverForm.BOARD_LAYOUTS_ID]
      ) {
        ticket.title = formValue[TurnoverForm.NAME];
      } else {
        ticket.boardLayoutsId = this.form.get(TurnoverForm.BOARD_LAYOUTS_ID).value;
        ticket.boardColumnUID = this.form.get(TurnoverForm.BOARD_COLUMN_UID).value;
      }

      if (this.data.ticketType === TicketType.Inspection) {
        this.store.dispatch(addInspectionTicket({ ticket }));
      } else {
        this.store.dispatch(addNewTurnoverTicket({ turnoverTicket }));
      }
    } else {
      const ticket: RestUpdateInspectionTicket = {
        id: formValue[TurnoverForm.ID],
        dueDate: formValue[TurnoverForm.END],
        description: formValue[TurnoverForm.DETAILED_INFORMATION],
        assignee: formValue[TurnoverForm.ASSIGNEE_ID],
        ticketPriorityType: formValue[TurnoverForm.TICKET_PRIORITY_TYPE],
        attachFileIds: this.attachmentItems.filter(a => this.needToAddAttachment(a)).map(a => a.fileUploadId),
        removeFileIds: this.ticket?.allAttachments.filter(a => this.needToRemoveAttachment(a)).map(a => a.fileUploadId),
        pushToPMS: formValue.pushToPMS,
        ticketTopicId: formValue.ticketTopicId,
        accessNotes: formValue.accessNotes,
        problemDescription: formValue.problemDescription,
        technicalNotes: formValue.technicalNotes,
        resolutionComment: formValue.resolutionComment,
        permissionToEnter: typeof formValue.permissionToEnter !== 'string' ? formValue.permissionToEnter : null,
        havePets: typeof formValue.havePets !== 'string' ? formValue.havePets : null,
        timeSpentLoggedManually: formValue.timeSpentLoggedManuallyInput
          ? this.ticketService.convertToSeconds(formValue.timeSpentLoggedManuallyInput)
          : null,
        totalCost: this.ticketService.parseCurrency(formValue.totalCostFormatted),
        totalCostStatus: formValue.totalCostStatus,
        paymentStatus: formValue.paymentStatusBool === true ? PaymentStatus.Paid : PaymentStatus.Unpaid,
        sparePartId: formValue.sparePartId,
        quantity: Number(formValue.quantity),
        responsibleParty: formValue.responsibleParty,
      };
      if (this.ticket?.pmsWorkOrderId) ticket.pmsWorkOrderId = this.ticket?.pmsWorkOrderId;
      if (this.ticket?.pmsWorkOrderNumber) ticket.pmsWorkOrderNumber = this.ticket?.pmsWorkOrderNumber;

      if (formValue[TurnoverForm.BOARD_LAYOUTS_ID] === this.customBoardOption.value) {
        ticket.title = formValue[TurnoverForm.NAME];
      } else {
        ticket.boardLayoutsId = this.form.get(TurnoverForm.BOARD_LAYOUTS_ID).value;
        ticket.boardColumnUID = this.form.get(TurnoverForm.BOARD_COLUMN_UID).value;
      }

      if (this.isCostTicket) {
        turnoverTicket.ticketType = TicketType.Cost;
        if (!turnoverTicket.totalCost) {
          turnoverTicket.totalCost = 0;
        }
      }

      if (this.data.fromServicePage) {
        this.store.dispatch(updateTicketPreview({ turnoverTicket }));
        return;
      }

      const selectedColumnId = this.form.get(TurnoverForm.BOARD_COLUMN_UID).value;
      if (selectedColumnId) {
        const selectedColumn = this.board.columns.find(column => column.id === selectedColumnId);
        if (!this.data.isNew && selectedColumn.type === BoardColumnType.Simple) {
          this.turnStepScheduleService
            .associateTicket({
              boardLayoutId: (ticket.boardLayoutsId = this.form.get(TurnoverForm.BOARD_LAYOUTS_ID).value),
              columnId: (ticket.boardColumnUID = this.form.get(TurnoverForm.BOARD_COLUMN_UID).value),
              ticketId: this.ticket.id,
              turnoverId: this.ticket.turnoverId,
            })
            .subscribe({
              next: () => {
                this.isEarlyAssociation = true;
                this.store.dispatch(updateTicketPreview({ turnoverTicket }));
              },
            });
        } else {
          this.store.dispatch(updateTicketPreview({ turnoverTicket }));
        }
      } else {
        this.store.dispatch(updateTicketPreview({ turnoverTicket }));
      }
    }
  }

  markAs(statusType: TicketStatusType): void {
    this.selectedStatus = TICKET_STATUS_TYPES.find(el => el.id === statusType);
    this.statusType = statusType;
  }

  private initForm() {
    if (this.formInitialized) return;
    this.formInitialized = true;

    this.fetchCategories().subscribe(() => {
      const categoryId = this.getCategoryByTopicId(this.ticket?.ticketTopicId);
      this.isPushToPMSDisabled = this.ticket?.pushToPMS || false;
      this.form.patchValue({
        [TurnoverForm.ID]: this.ticket?.id || 0,
        [TurnoverForm.NAME]: this.ticket?.name || this.data.title || null,
        [TurnoverForm.DETAILED_INFORMATION]: this.ticket?.detailedInformation || this.data.description || null,
        [TurnoverForm.ASSIGNEE_ID]: this.ticket?.assigneeId || this.data.assigneeId,
        [TurnoverForm.END]: getDateObjectFunction(this.ticket?.endDate || new Date()),
        [TurnoverForm.TICKET_PRIORITY_TYPE]: this.ticket?.ticketPriorityType || PriorityType.Medium,
        [TurnoverForm.TICKET_STATUS_TYPE]: this.ticket?.ticketStatusType || TicketStatusType.InProgress,
        [TurnoverForm.ABLE_TO_RESOLVE]: this.ticketService.checkPermissionToEdit(this.userData, this.ticket),
        [TurnoverForm.BOARD_LAYOUTS_ID]: this.ticket?.boardLayoutsId || this.customBoardOption.value,
        [TurnoverForm.BOARD_COLUMN_UID]: this.ticket?.boardColumnUID || null,
        pushToPMS: this.ticket?.pushToPMS || false,
        ticketCategoryId: categoryId,
        ticketTopicId: this.ticket?.ticketTopicId ? this.ticket?.ticketTopicId : null,
        permissionToEnter:
          this.ticket?.permissionToEnter !== undefined && this.ticket?.permissionToEnter !== null
            ? this.ticket?.permissionToEnter
            : 'null',
        havePets:
          this.ticket?.havePets !== undefined && this.ticket?.havePets !== null ? this.ticket?.havePets : 'null',
        accessNotes: this.ticket?.accessNotes || '',
        problemDescription: this.ticket?.problemDescription || '',
        technicalNotes: this.ticket?.technicalNotes || '',
        resolutionComment: this.ticket?.resolutionComment || '',
        timeSpentLoggedManuallyInput: this.ticket?.timeSpentLoggedManually
          ? this.ticketService.convertToTimeSpent(this.ticket.timeSpentLoggedManually)
          : null,
        totalCostFormatted: this.ticket?.totalCost ? this.ticketService.formatCurrency(this.ticket.totalCost) : '',
        totalCostStatus: this.ticket?.totalCostStatus ?? TotalCostStatus.Final,
        paymentStatusBool: this.ticket?.paymentStatus === PaymentStatus.Paid ? true : false,
        responsibleParty: this.ticket?.responsibleParty || null,
        sparePartId: this.ticket?.sparePartId || null,
        quantity: this.ticket?.quantity || null,
      });

      this.handleBoardControls();
      this.onBoardLayoutChange();
      this.onBoardScheduleTypeChange();

      this.form
        .get('pushToPMS')
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe(isPushToPMS => {
          const categoryControl = this.form.get('ticketCategoryId');
          const topicControl = this.form.get('ticketTopicId');

          if (isPushToPMS) {
            categoryControl.setValidators([Validators.required]);
            topicControl.setValidators([Validators.required]);
          } else {
            categoryControl.clearValidators();
            topicControl.clearValidators();
          }

          categoryControl.updateValueAndValidity();
          topicControl.updateValueAndValidity();
        });

      this.attachmentItems = [];
      if (this.data.isNew && this.data.attachments?.length) {
        this.attachmentItems.push(
          ...this.data.attachments.map(a => ({
            id: -1,
            fileUploadId: a.id,
            originalFileName: a.originalFileName,
            size: a.size,
            url: a.cloudUri,
          }))
        );
      }
      if (!this.data.isNew && this.ticket?.allAttachments?.length) {
        this.attachmentItems.push(
          ...this.ticket.allAttachments.map(a => ({
            id: a.id,
            fileUploadId: a.fileUploadId,
            originalFileName: a.originalFileName,
            size: a.size,
            url: a.url,
          }))
        );
      }

      this.form.controls['ticketCategoryId'].valueChanges.pipe(untilDestroyed(this)).subscribe(categoryId => {
        const selectedCategory = this.categories.find(category => category.value === categoryId);
        this.topics = selectedCategory ? selectedCategory.topics : [];
        if (this.topics.length > 0) {
          this.form.controls['ticketTopicId'].enable();
        } else {
          this.form.controls['ticketTopicId'].disable();
          this.form.controls['ticketTopicId'].reset();
        }
        this.cdr.detectChanges();
      });

      if (categoryId) {
        const selectedCategory = this.categories.find(category => category.value === categoryId);
        this.topics = selectedCategory ? selectedCategory.topics : [];
        if (this.topics.length > 0) {
          this.form.controls['ticketTopicId'].enable();
        } else {
          this.form.controls['ticketTopicId'].disable();
          this.form.controls['ticketTopicId'].reset();
        }
      }

      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  private needToCopyAttachment(attachment: RestGenericTypedAttachment) {
    return this.data?.attachments?.some(a => a.id === attachment.id);
  }

  private needToAddAttachment(attachment: RestGenericTypedAttachment) {
    return !this.ticket || !this.ticket.allAttachments?.find(a => a.id === attachment.id);
  }

  private needToRemoveAttachment(attachment: RestGenericTypedAttachment) {
    return !this.attachmentItems.find(a => a.id === attachment.id);
  }

  private setStatusTypes(): void {
    let statusTypes: { label: string; id: TicketStatusType; disabled?: boolean }[] = TICKET_STATUS_TYPES;
    this.ticketStatusLabel = statusTypes.find(el => el.id === this.ticket?.ticketStatusType)?.label;
    statusTypes = statusTypes.map(el => ({
      ...el,
      disabled: el.id === this.ticket.ticketStatusType,
    }));
    this.ticketStatusOptions$.next(statusTypes);
  }

  @Output() ticketStatusChange = new EventEmitter<TicketStatusType>();

  private onGetTicketStatusType(type: TicketStatusType): TicketStatusType {
    if (type === this.ticketStatusTypeValues.Todo) return TicketStatusType.Todo;
    if (type === this.ticketStatusTypeValues.InProgress) return TicketStatusType.InProgress;
    if (type === this.ticketStatusTypeValues.Resolved) return TicketStatusType.Resolved;
    if (type === this.ticketStatusTypeValues.Canceled) {
      this.ticketStatusChange.emit(TicketStatusType.Canceled);
      return TicketStatusType.Canceled;
    }
    if (type === this.ticketStatusTypeValues.Pending) return TicketStatusType.Pending;
    if (type === this.ticketStatusTypeValues.Declined) return TicketStatusType.Declined;
    if (type === this.ticketStatusTypeValues.WaitingParts) return TicketStatusType.WaitingParts;

    return TicketStatusType.Reopened;
  }

  private handleBoardControls(): void {
    this.form.get([TurnoverForm.BOARD_LAYOUTS_ID]).value === this.customBoardOption.value
      ? this.form.get([TurnoverForm.BOARD_COLUMN_UID]).disable()
      : this.form.get([TurnoverForm.NAME]).disable();

    if (this.data.ticketId && this.ticket?.boardColumnUID) {
      this.form.get([TurnoverForm.BOARD_LAYOUTS_ID]).disable();
      this.form.get([TurnoverForm.BOARD_COLUMN_UID]).disable();
    }

    this.form.updateValueAndValidity();
  }

  private onBoardLayoutChange(): void {
    this.form
      ?.get(TurnoverForm.BOARD_LAYOUTS_ID)
      ?.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(boardId => {
        if (boardId === this.customBoardOption.value) {
          this.form.get([TurnoverForm.NAME]).enable();
          this.form.get([TurnoverForm.BOARD_COLUMN_UID]).disable();
        } else {
          this.form.get([TurnoverForm.NAME]).disable();
          this.form.get([TurnoverForm.BOARD_COLUMN_UID]).enable();
          this.form.get([TurnoverForm.BOARD_COLUMN_UID]).setValue(null);
          this.onGetFilteredBoardList(boardId);
        }
        this.form.updateValueAndValidity();
      });
  }

  private onBoardScheduleTypeChange(): void {
    this.form
      ?.get(TurnoverForm.BOARD_COLUMN_UID)
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
        filter(el => !!el)
      )
      .subscribe(columnId => {
        if (columnId === 'customSchedule') {
          this.form.get(TurnoverForm.BOARD_COLUMN_UID).setValue(null, { emitEvent: false });
          this.onAddNewColumnDialog(
            this.isPunchTicket ? TicketType.Punch : this.isInspectionTicket ? TicketType.Inspection : TicketType.Turn
          );
          return;
        }
        if (columnId === 'customCost') {
          this.form.get(TurnoverForm.BOARD_COLUMN_UID).setValue(null, { emitEvent: false });
          this.onAddNewColumnDialog(TicketType.Cost);
          return;
        }
        this.form.updateValueAndValidity();
      });
  }

  private onGetFilteredBoardList(boardId?: number): void {
    this.boardService
      .getForceServerReloadList()
      .pipe(
        filter(list => !!list),
        map(list => {
          this.boardList = list;
          const filteredConnectedBoard =
            this.turnoverData?.boardConnections && this.turnoverData.boardConnections.map(el => el.boardId);
          const connectedBoardIdsSet = new Set(filteredConnectedBoard);
          return list.filter(el => connectedBoardIdsSet.has(el.id));
        }),
        map(list => list.map(el => ({ value: el.id, label: el.name }))),
        map(list => list.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }))),
        map(list => [this.customBoardOption, ...list]),
        tap(list => {
          this.boardList$.next(list);
          if (boardId) this.createColumnsSelector(boardId);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private createColumnsSelector(boardId: number): void {
    const boardItem = this.boardList.find(el => el.id === boardId);
    if (!boardItem) return;

    this.board = new Board(boardItem);
    const turnStepSchedulesBoardIds = this.turnoverData?.turnStepSchedules?.map(el => el.boardColumnUID) || [];
    const costTicketsBoardColumnIds = this.turnoverData?.tickets?.map(el => el.boardColumnUID) || [];
    const turnStepSchedulesBoardIdsSet = new Set(turnStepSchedulesBoardIds);
    const costTicketsBoardColumnIdsSet = new Set(costTicketsBoardColumnIds);
    const boardColumns = this.board?.columns || null;
    const unfrozenColumns = boardColumns?.filter(
      el =>
        (!el.frozen && el.type === BoardColumnType.CustomAmount) || (!el.frozen && el.type === BoardColumnType.Simple)
    );
    const skipStepScheduleCheck = this.ticket?.boardLayoutsId && this.ticket?.boardColumnUID;
    if (!unfrozenColumns) {
      this.boardColumns$.next(null);
      return;
    }
    const mappedBoardColumn = unfrozenColumns
      .map(el => ({
        value: el.id,
        label: el.name,
      }))
      .filter(
        el =>
          skipStepScheduleCheck ||
          (!turnStepSchedulesBoardIdsSet.has(el.value) && !costTicketsBoardColumnIdsSet.has(el.value))
      )
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));

    mappedBoardColumn.push(
      {
        value: 'customSchedule',
        label: `Add schedule for ${boardItem.name}`,
      },
      {
        value: 'customCost',
        label: `Add custom cost for ${boardItem.name}`,
      }
    );

    this.boardColumns$.next(mappedBoardColumn);
  }

  private onAddNewColumnDialog(ticketType: TicketType): void {
    const boardName = this.boardList.find(el => el.id === this.boardLayoutsId.value)?.name;
    this.dialog
      .open<CreateBoardColumnModalComponent>(CreateBoardColumnModalComponent, {
        data: { boardName, ticketType },
        maxWidth: '400px',
      })
      .afterClosed()
      .subscribe(formData => {
        if (formData?.columnName) this.addNewColumnToBoard(formData.columnName, ticketType);
        this.isCostTicket = ticketType === TicketType.Cost;
      });
  }

  private addNewColumnToBoard(columnName: string, ticketType: TicketType): void {
    this.newColumnLoading$.next(true);
    const newColumn = this.setColumn(
      ticketType === TicketType.Cost ? BoardColumnType.CustomAmount : BoardColumnType.Simple,
      columnName,
      { name: columnName }
    );
    this.board.columns.push(newColumn);
    this.boardService.update(new RestBoardModel(this.board)).subscribe(() => this.updateFormColumnField(newColumn.id));
  }

  private setColumn(type: BoardColumnType, columnName: string, columnOptions?: ColumnOptions): BoardColumn {
    return {
      name: columnName,
      type: type,
      editable: columnOptions?.editable ?? true,
      frozen: false,
      visible: true,
      id: uuidv4(),
    };
  }

  private updateFormColumnField(newColumnId: string): void {
    this.createColumnsSelector(this.boardLayoutsId?.value);
    this.form.get(TurnoverForm.BOARD_COLUMN_UID).setValue(newColumnId);
    this.newColumnLoading$.next(false);
  }

  private onAttachTicket(ticketId: number, boardLayoutId: number, columnId: string, turnoverId: number): void {
    if (this.isCostTicket) {
      return;
    }

    this.turnStepScheduleService
      .associateTicket({
        boardLayoutId,
        columnId,
        ticketId,
        turnoverId,
      })
      .subscribe();
  }

  getHeader(): string {
    return (
      (this.data?.isNew
        ? this.isPunchTicket
          ? 'New punch: '
          : this.isCostTicket
          ? 'New cost: '
          : 'New ticket: '
        : '') +
      (this.isPunchTicket && !this.data.isNew
        ? 'Punch: '
        : this.data.ticketType === 5 && !this.data.isNew
        ? 'Cost: '
        : '') +
      (this.data?.propertyName || '') +
      ' - ' +
      (this.data?.unitName || '')
    );
  }
}
