import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { AppRoutes } from '@shared/constants/app-routes.const';
import { AuthService } from '@shared/services/auth.service';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private snackbarService: SnackbarService, private router: Router) {}

  private excludedRoutes: string[] = [];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/assets/')) return next.handle(request);

    if (request.url.includes(environment.azureAuthConfig.stsBase)) {
      return next.handle(request);
    }

    return this.authService.getAccessToken().pipe(
      mergeMap(token => {
        if (token) {
          const clone = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'X-Correlation-Id': uuidv4(),
            },
          });
          return next.handle(clone).pipe(
            catchError(err => {
              const isExcludedRoute = this.excludedRoutes.some(url => request.url.includes(url));

              if (err.status === 401 && !isExcludedRoute) {
                this.snackbarService.error(SnackbarErrorMessage.SessionIsOver);
                this.router.navigate([AppRoutes.LOGIN]);
              }
              return throwError(() => err);
            })
          );
        } else {
          this.router.navigate([AppRoutes.LOGIN]);
          return throwError(() => 'Empty token');
        }
      })
    );
  }
}
